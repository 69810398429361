<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderWarranty.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderWarranty.label.anticipation')"
          label-for="anticipation-input"
        >
          <b-form-input
            id="anticipation-input"
            trim
            data-test="anticipation-input"
            :readonly="readonly"
            :state="checkField('anticipation')"
            :value="formData.anticipation"
            @update="dataInput('anticipation', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderWarranty.label.anticipationDevolution')"
          label-for="anticipation-devolution-input"
        >
          <b-form-input
            id="anticipation-devolution-input"
            trim
            data-test="anticipation-devolution-input"
            :readonly="readonly"
            :state="checkField('anticipationDevolution')"
            :value="formData.anticipationDevolution"
            @update="dataInput('anticipationDevolution', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderWarranty.label.letterOfGuarantee')"
          label-for="letter-of-guarantee-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="letter-of-guarantee-input"
              class="form-control"
              data-test="letter-of-guarantee-input"
              v-bind="money"
              :class="checkMoneyState('letterOfGuarantee')"
              :readonly="readonly"
              :value="moneyInput(formData.letterOfGuarantee)"
              @input="moneyOutput('letterOfGuarantee', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderWarranty.label.hasResidual')"
          label-for="letter-of-guarantee-input"
        >
          <b-form-checkbox v-model="formData.hasResidual" name="check-button" :disabled="readonly" switch>
            <b>{{ formData.hasResidual ?
              $t('orderModal.sections.orderWarranty.label.yes') :
              $t('orderModal.sections.orderWarranty.label.no') }}</b>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          label="Valor garantido"
          label-for="guaranteed-value-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="guaranteed-value-input"
              class="form-control"
              data-test="guaranteed-value-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.guaranteedValue)"
              @input="moneyOutput('guaranteedValue', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.payDay')"
          label-for="pay-day-input"
        >
          <date-picker
            id="pay-day-input"
            v-model="dateInputs['payDay']"
            data-test="pay-day-input"
            :hide="readonly"
            @change="setDate('payDay', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          label="Valor recebido"
          label-for="amount-received-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="amount-received-input"
              class="form-control"
              data-test="amount-received-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.amountReceived)"
              @input="moneyOutput('amountReceived', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          label="Saldo devedor"
          label-for="debt-balance-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="debt-balance-input"
              class="form-control"
              data-test="debt-balance-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.debtBalance)"
              @input="moneyOutput('debtBalance', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12">
        <b-form-group
          :label="$t('orderModal.sections.orderWarranty.label.anticipationObs')"
          label-for="anticipation-obs-input"
        >
          <b-form-textarea
            id="anticipation-obs-input"
            :value="formData.anticipationObs"
            :readonly="readonly"
            @update="dataInput('anticipationObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderWarranty',
  mixins: [orderInputsMixin],
  mounted() {
    this.dateInputs['payDay'] = this.dateToUtcDateTime(this.formData.payDay);
  },
}
</script>

<style scoped>

</style>
