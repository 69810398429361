import { mutate } from '@utils/apollo';
import createMutation from '@graphql/order/mutations/create.gql';
import updateMutation from '@graphql/order/mutations/update-id.gql';
import transformObjectKeys from 'transform-object-keys';

export const create = (payload = {}, graphqlQuery = createMutation) => {
  const variables = {
    order_input: transformObjectKeys(
      payload,
      { deep: true, snakeCase: true })
  };

  return mutate(
    graphqlQuery,
    variables,
  );
}

export const update = (payload = {}, graphqlQuery = updateMutation) => {
  const variables = transformObjectKeys(
    payload,
    { deep: true, snakeCase: true });

  return mutate(
    graphqlQuery,
    variables,
  );
}
