var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("orderModal.sections.orderInsuranceClaim.title")
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInsuranceClaim.label.incidentAlertTime"
                    ),
                    "label-for": "incident-alert-time-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "incident-alert-time-input",
                      trim: "",
                      "data-test": "incident-alert-time-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("incidentAlertTime"),
                      value: _vm.formData.incidentAlertTime,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("incidentAlertTime", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInsuranceClaim.label.incidentContactEmail"
                    ),
                    "label-for": "incident-contact-email-input",
                    state: _vm.checkField("incidentContactEmail"),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "incident-contact-email-input",
                      trim: "",
                      "data-test": "incident-contact-email-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.incidentContactEmail,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("incidentContactEmail", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInsuranceClaim.label.incidentDateBoEmitted"
                    ),
                    "label-for": "incident-date-bo-emitted-input",
                    state: _vm.checkField("incidentDateBoEmitted"),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "incident-date-bo-emitted-input",
                      trim: "",
                      "data-test": "incident-date-bo-emitted-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("incidentDateBoEmitted"),
                      value: _vm.formData.incidentDateBoEmitted,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("incidentDateBoEmitted", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }