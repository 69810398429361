<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderInsuranceClaim.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsuranceClaim.label.incidentAlertTime')"
          label-for="incident-alert-time-input"
        >
          <b-form-input
            id="incident-alert-time-input"
            trim
            data-test="incident-alert-time-input"
            :readonly="readonly"
            :state="checkField('incidentAlertTime')"
            :value="formData.incidentAlertTime"
            @update="dataInput('incidentAlertTime', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsuranceClaim.label.incidentContactEmail')"
          label-for="incident-contact-email-input"
          :state="checkField('incidentContactEmail')"
        >
          <b-form-input
            id="incident-contact-email-input"
            trim
            data-test="incident-contact-email-input"
            :readonly="readonly"
            :value="formData.incidentContactEmail"
            @update="dataInput('incidentContactEmail', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsuranceClaim.label.incidentDateBoEmitted')"
          label-for="incident-date-bo-emitted-input"
          :state="checkField('incidentDateBoEmitted')"
        >
          <b-form-input
            id="incident-date-bo-emitted-input"
            trim
            data-test="incident-date-bo-emitted-input"
            :readonly="readonly"
            :state="checkField('incidentDateBoEmitted')"
            :value="formData.incidentDateBoEmitted"
            @update="dataInput('incidentDateBoEmitted', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderInsuranceClaim',
  mixins: [orderInputsMixin],
}
</script>

<style scoped>

</style>
