var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderFines.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderFines.label.trafficFine"
                    ),
                    "label-for": "traffic-fine-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "traffic-fine-input",
                      "data-test": "traffic-fine-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["organizationOptions"],
                      state: _vm.checkField("trafficFine"),
                      value: _vm.formData.trafficFine,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("trafficFine", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderFines.label.trafficResponsable"
                    ),
                    "label-for": "traffic-responsable-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "traffic-responsable-input",
                      trim: "",
                      "data-test": "traffic-responsable-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.trafficResponsable,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("trafficResponsable", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderFines.label.trafficAdmRate"
                    ),
                    "label-for": "traffic-adm-rate-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { append: "%" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "traffic-adm-rate-input",
                          trim: "",
                          "data-test": "traffic-adm-rate-input",
                          type: "number",
                          number: "",
                          readonly: _vm.readonly,
                          value: _vm.formData.trafficAdmRate,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.dataInput("trafficAdmRate", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderFines.label.trafficObs"
                    ),
                    "label-for": "traffic-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "traffic-obs-input",
                      "data-test": "traffic-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.trafficObs,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("trafficObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }