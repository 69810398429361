<template>
  <div>
    <b-dd-divider class="mb-4"/>

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderBooking.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.pricingAmount')"
          label-for="pricing-amount-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="pricing-amount-input"
              class="form-control"
              data-test="pricing-amount-input"
              v-bind="money"
              :readonly="readonly"
              :class="checkMoneyState('pricingAmount')"
              :value="moneyInput(formData.pricingAmount)"
              @input="moneyOutput('pricingAmount', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.kmMax')"
          label-for="km-max-input"
        >
          <b-form-input
            id="km-max-input"
            :value="formData.kmMax"
            trim
            data-test="km-max-input"
            number
            type="number"
            :readonly="readonly"
            :state="checkField('kmMax')"
            @update="dataInput('kmMax', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.kmContracted')"
          label-for="km-contracted-input"
        >
          <b-form-input
            id="km-contracted-input"
            :value="getKmContracted()"
            readonly
            trim
            data-test="km-contracted-input"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.pricingExtraKm')"
          label-for="pricing-extra-km-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="pricing-extra-km-input"
              class="form-control"
              data-test="pricing-extra-km-input"
              v-bind="money"
              :class="checkMoneyState('pricingExtraKm')"
              :readonly="readonly"
              :value="moneyInput(formData.pricingExtraKm)"
              @input="moneyOutput('pricingExtraKm', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.kmLimitExceeded')"
          label-for="km-limit-exceeded-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="km-limit-exceeded-input"
              trim
              data-test="km-limit-exceeded-input"
              number
              type="number"
              :readonly="readonly"
              :value="formData.kmLimitExceeded"
              @update="dataInput('kmLimitExceeded', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.poolKm')"
          label-for="pool-km-input"
        >
          <b-form-select
            id="pool-km-input"
            :value="formData.poolKm"
            trim
            data-test="pool-km-input"
            :disabled="readonly"
            :state="checkField('poolKm')"
            :options="inputOptions['poolKm']"
            @change="dataInput('poolKm', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.notRun')"
          label-for="not-run-input"
        >
          <b-form-input
            id="not-run-input"
            trim
            data-test="not-run-input"
            number
            type="number"
            :readonly="readonly"
            :value="formData.notRun"
            @update="dataInput('notRun', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.model')"
          label-for="model-input"
        >
          <b-form-input
            id="model-input"
            :value="formData.model"
            trim
            data-test="model-input"
            :readonly="readonly"
            :state="checkField('model')"
            @update="dataInput('model', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-form-group
          :label="$t('orderModal.sections.orderBooking.label.bookingObs')"
          label-for="booking-obs-input"
        >
          <b-form-textarea
            id="booking-obs-input"
            data-test="booking-obs-input"
            :readonly="readonly"
            :value="formData.bookingObs"
            @update="dataInput('bookingObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderBooking',
  mixins: [orderInputsMixin],
  methods: {
    getKmContracted() {
      this.formData.kmContracted = this.formData.period * this.formData.kmMax || 0;
      return this.formData.kmContracted;
    },
  }
}
</script>

<style scoped>

</style>
