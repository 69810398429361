import {
  BillingKindEnum,
  LocalEnum,
  OrderTypeEnum,
  OrganizationEnum,
  OrganizationNullableEnum,
  STATUS
} from '@enums/order';
import { Money } from 'v-money'

export const orderInputsMixin = {
  components: {
    Money
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    formMode: {
      type: String,
      default: 'create'
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    contractId: {
      type: String,
      default: null,
    },
    validator: {
      type: Object,
      default: () => {},
    },
    submitClick: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dateInputs: {
        'purchaseDate': '',
        'saleDate': '',
        'signedAt': '',
        'payDay': '',
      },
      maskThousand: [
        '#####,##',
        '####,##',
        '###,##',
        '##,##',
        '#,##',
      ],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    inputOptions() {
      return {
        billingKind: [
          {
            value: BillingKindEnum.BILLING_ANTICIPATED,
            text: this.$t(`orderModal.enums.billingKindEnum.${BillingKindEnum.BILLING_ANTICIPATED}`)
          },
          {
            value: BillingKindEnum.BILLING_FILL_MONTH,
            text: this.$t(`orderModal.enums.billingKindEnum.${BillingKindEnum.BILLING_FILL_MONTH}`)
          },
          {
            value: BillingKindEnum.BILLING_PRO_RATA,
            text: this.$t(`orderModal.enums.billingKindEnum.${BillingKindEnum.BILLING_PRO_RATA}`)
          },
        ],
        poolKm: [
          { value: true, text: 'Sim' },
          { value: false, text: 'Não' },
        ],
        localEnumOptions: [
          {
            value: LocalEnum.KOVI,
            text: this.$t(`orderModal.enums.localEnum.${LocalEnum.KOVI}`)
          },
          {
            value: LocalEnum.OTHERS,
            text: this.$t(`orderModal.enums.localEnum.${LocalEnum.OTHERS}`)
          },
        ],
        status: [
          {
            value: STATUS.ACTIVE,
            text: this.$t('orderModal.enums.status.ACTIVE')
          },
          {
            value: STATUS.INACTIVE,
            text: this.$t('orderModal.enums.status.INACTIVE')
          },
        ],
        type: [
          {
            value: OrderTypeEnum.NEW,
            text: this.$t('orderModal.enums.orderTypeEnum.NEW')
          },
          {
            value: OrderTypeEnum.INCLUSION,
            text: this.$t('orderModal.enums.orderTypeEnum.INCLUSION')
          },
          {
            value: OrderTypeEnum.RENOVATION,
            text: this.$t('orderModal.enums.orderTypeEnum.RENOVATION')
          },
        ],
        organizationOptions: [
          {
            value: OrganizationEnum.KOVI,
            text: this.$t(`orderModal.enums.organizationEnum.${OrganizationEnum.KOVI}`)
          },
          {
            value: OrganizationEnum.LOCADORA,
            text: this.$t(`orderModal.enums.organizationEnum.${OrganizationEnum.LOCADORA}`)
          },
        ],
        organizationNullableOptions: [
          {
            value: OrganizationNullableEnum.KOVI,
            text: this.$t(`orderModal.enums.organizationEnum.${OrganizationNullableEnum.KOVI}`)
          },
          {
            value: OrganizationNullableEnum.LOCADORA,
            text: this.$t(`orderModal.enums.organizationEnum.${OrganizationNullableEnum.LOCADORA}`)
          },
          {
            value: OrganizationNullableEnum.NONE,
            text: this.$t(`orderModal.enums.organizationEnum.${OrganizationNullableEnum.NONE}`)
          },
        ],
      }
    },
  },
  methods: {
    checkField(field) {
      return this.submitClick
        ? !this.validator.formData[field]?.$error
        : null;
    },
    checkFieldResidual(field, index, field2) {
      return this.submitClick
        ? !this.validator.formData[field].$each[index][field2]?.$error
        : null;
    },
    dataInput(inputName, value) {
      this.$emit('change', {
        name: inputName,
        data: value,
      })
    },
    moneyInput(input){
      if (!input) return;

      return parseFloat(input) / 100
    },
    moneyOutput(key, data) {
      if (isNaN(data)) return;

      const value = (data > 0) ? Math.round(data * 100) : 0;

      this.dataInput(key, value);
    },
    checkMoneyState(input) {
      // return this.checkField(input) ? 'is-invalid' : ''
      return {
        'is-invalid': (this.checkField(input) === false),
        'is-valid': (this.checkField(input) === true),
      }
    },
    checkMoneyResidualState(input, index, input2) {
      // return this.checkField(input) ? 'is-invalid' : ''
      return {
        'is-invalid': (this.checkFieldResidual(input, index, input2) === false),
        'is-valid': (this.checkFieldResidual(input, index, input2) === true),
      }
    },
    setDate(id, value) {
      const formatDate = new Date(value).toISOString();
      this.dateInputs[id] = formatDate;
      this.dataInput(id, formatDate);
    },

    dateToUtcDateTime(value) {
      return `${value}T00:00:00`;
    }
  },
}
