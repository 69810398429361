<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderFines.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderFines.label.trafficFine')"
          label-for="traffic-fine-input"
        >
          <b-form-select
            id="traffic-fine-input"
            data-test="traffic-fine-input"
            :disabled="readonly"
            :options="inputOptions['organizationOptions']"
            :state="checkField('trafficFine')"
            :value="formData.trafficFine"
            @change="dataInput('trafficFine', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderFines.label.trafficResponsable')"
          label-for="traffic-responsable-input"
        >
          <b-form-input
            id="traffic-responsable-input"
            trim
            data-test="traffic-responsable-input"
            :readonly="readonly"
            :value="formData.trafficResponsable"
            @update="dataInput('trafficResponsable', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderFines.label.trafficAdmRate')"
          label-for="traffic-adm-rate-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="traffic-adm-rate-input"
              trim
              data-test="traffic-adm-rate-input"
              type="number"
              number
              :readonly="readonly"
              :value="formData.trafficAdmRate"
              @update="dataInput('trafficAdmRate', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderFines.label.trafficObs')"
          label-for="traffic-obs-input"
        >
          <b-form-textarea
            id="traffic-obs-input"
            data-test="traffic-obs-input"
            :readonly="readonly"
            :value="formData.trafficObs"
            @update="dataInput('trafficObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderFines',
  mixins: [orderInputsMixin],
}
</script>

<style scoped>

</style>
