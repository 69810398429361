import {
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  integer,
} from 'vuelidate/lib/validators';

export default {
  formData: {
    purchaseOptions: {
      $each: {
        period: {
          numeric,
          minValue: minValue(1),
          isUnique(value) {
            return this.formData.purchaseOptions.filter((el) => el.period === value).length < 2
          }
        },
        purchaseValue: {
          minValue: minValue(0)
        }
      },
    },
    contract: {
      required,
    },
    signedAt: {
      required,
    },
    volume: {
      required,
      integer,
      minValue: minValue(0),
    },
    period: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(99),
    },
    status: {
      required,
    },
    type: {
      required,
    },
    pricingAmount: {
      required,
      minValue: minValue(0),
    },
    kmMax: {
      required,
      minValue: minValue(0),
    },
    pricingExtraKm: {
      required,
      minValue: minValue(0),
    },
    poolKm: {
      required,
    },
    model: {
      required,
      minLength: minLength(3),
    },
    trackerType: {
      required,
    },
    safecarType: {
      required,
    },
    anticipation: {
      required,
      minLength: minLength(3),
    },
    anticipationDevolution: {
      required,
      minLength: minLength(3),
    },
    letterOfGuarantee: {
      required,
      numeric,
    },
    assistenceType: {
      required,
    },
    trafficFine: {
      required,
    },
    securyHillType: {
      required,
    },
    securyHillResponsable: {
      required,
      minLength: minLength(3),
    },
    securyHillFranchise: {
      required,
      numeric,
      minValue: minValue(0),
    },
    securyPtFipeMetrics: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
    securyPtFipePayment: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
    securyRcfType: {
      required,
    },
    securyRcfResponsable: {
      required,
      minLength: minLength(3),
    },
    securyRcfFranchise: {
      required,
      minValue: minValue(0),
    },
    incidentAlertTime: {
      required,
      minLength: minLength(3),
    },
    incidentDateBoEmitted: {
      required,
      minLength: minLength(3),
    },
    billingKind: {
      required,
    },
    billingOverdue: {
      required,
      minLength: minLength(3),
    },
    billingReadjust: {
      required,
      minLength: minLength(3),
    },
    retentionFine: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
    deliveryLocal: {
      required,
    },
    devolutionLocal: {
      required,
    }
  }
}
