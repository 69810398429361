var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderWarranty.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderWarranty.label.anticipation"
                    ),
                    "label-for": "anticipation-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "anticipation-input",
                      trim: "",
                      "data-test": "anticipation-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("anticipation"),
                      value: _vm.formData.anticipation,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("anticipation", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderWarranty.label.anticipationDevolution"
                    ),
                    "label-for": "anticipation-devolution-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "anticipation-devolution-input",
                      trim: "",
                      "data-test": "anticipation-devolution-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("anticipationDevolution"),
                      value: _vm.formData.anticipationDevolution,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("anticipationDevolution", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderWarranty.label.letterOfGuarantee"
                    ),
                    "label-for": "letter-of-guarantee-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("letterOfGuarantee"),
                            attrs: {
                              id: "letter-of-guarantee-input",
                              "data-test": "letter-of-guarantee-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.letterOfGuarantee
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput(
                                  "letterOfGuarantee",
                                  $event
                                )
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderWarranty.label.hasResidual"
                    ),
                    "label-for": "letter-of-guarantee-input",
                  },
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        name: "check-button",
                        disabled: _vm.readonly,
                        switch: "",
                      },
                      model: {
                        value: _vm.formData.hasResidual,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "hasResidual", $$v)
                        },
                        expression: "formData.hasResidual",
                      },
                    },
                    [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.formData.hasResidual
                              ? _vm.$t(
                                  "orderModal.sections.orderWarranty.label.yes"
                                )
                              : _vm.$t(
                                  "orderModal.sections.orderWarranty.label.no"
                                )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Valor garantido",
                    "label-for": "guaranteed-value-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            attrs: {
                              id: "guaranteed-value-input",
                              "data-test": "guaranteed-value-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.guaranteedValue
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput(
                                  "guaranteedValue",
                                  $event
                                )
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.payDay"
                    ),
                    "label-for": "pay-day-input",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "pay-day-input",
                      "data-test": "pay-day-input",
                      hide: _vm.readonly,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setDate("payDay", $event)
                      },
                    },
                    model: {
                      value: _vm.dateInputs["payDay"],
                      callback: function ($$v) {
                        _vm.$set(_vm.dateInputs, "payDay", $$v)
                      },
                      expression: "dateInputs['payDay']",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Valor recebido",
                    "label-for": "amount-received-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            attrs: {
                              id: "amount-received-input",
                              "data-test": "amount-received-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.amountReceived
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput("amountReceived", $event)
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Saldo devedor",
                    "label-for": "debt-balance-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            attrs: {
                              id: "debt-balance-input",
                              "data-test": "debt-balance-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(_vm.formData.debtBalance),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput("debtBalance", $event)
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderWarranty.label.anticipationObs"
                    ),
                    "label-for": "anticipation-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "anticipation-obs-input",
                      value: _vm.formData.anticipationObs,
                      readonly: _vm.readonly,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("anticipationObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }