var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderBooking.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.pricingAmount"
                    ),
                    "label-for": "pricing-amount-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("pricingAmount"),
                            attrs: {
                              id: "pricing-amount-input",
                              "data-test": "pricing-amount-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(_vm.formData.pricingAmount),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput("pricingAmount", $event)
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.kmMax"
                    ),
                    "label-for": "km-max-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "km-max-input",
                      value: _vm.formData.kmMax,
                      trim: "",
                      "data-test": "km-max-input",
                      number: "",
                      type: "number",
                      readonly: _vm.readonly,
                      state: _vm.checkField("kmMax"),
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("kmMax", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.kmContracted"
                    ),
                    "label-for": "km-contracted-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "km-contracted-input",
                      value: _vm.getKmContracted(),
                      readonly: "",
                      trim: "",
                      "data-test": "km-contracted-input",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.pricingExtraKm"
                    ),
                    "label-for": "pricing-extra-km-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("pricingExtraKm"),
                            attrs: {
                              id: "pricing-extra-km-input",
                              "data-test": "pricing-extra-km-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.pricingExtraKm
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput("pricingExtraKm", $event)
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.kmLimitExceeded"
                    ),
                    "label-for": "km-limit-exceeded-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { append: "%" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "km-limit-exceeded-input",
                          trim: "",
                          "data-test": "km-limit-exceeded-input",
                          number: "",
                          type: "number",
                          readonly: _vm.readonly,
                          value: _vm.formData.kmLimitExceeded,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.dataInput("kmLimitExceeded", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.poolKm"
                    ),
                    "label-for": "pool-km-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "pool-km-input",
                      value: _vm.formData.poolKm,
                      trim: "",
                      "data-test": "pool-km-input",
                      disabled: _vm.readonly,
                      state: _vm.checkField("poolKm"),
                      options: _vm.inputOptions["poolKm"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("poolKm", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.notRun"
                    ),
                    "label-for": "not-run-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "not-run-input",
                      trim: "",
                      "data-test": "not-run-input",
                      number: "",
                      type: "number",
                      readonly: _vm.readonly,
                      value: _vm.formData.notRun,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("notRun", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.model"
                    ),
                    "label-for": "model-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "model-input",
                      value: _vm.formData.model,
                      trim: "",
                      "data-test": "model-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("model"),
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("model", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBooking.label.bookingObs"
                    ),
                    "label-for": "booking-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "booking-obs-input",
                      "data-test": "booking-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.bookingObs,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("bookingObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }