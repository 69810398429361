<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderAssistance.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderAssistance.label.assistenceType')"
          label-for="assistence-type-input"
        >
          <b-form-select
            id="assistence-type-input"
            data-test="assistence-type-input"
            :disabled="readonly"
            :options="inputOptions['organizationOptions']"
            :state="checkField('assistenceType')"
            :value="formData.assistenceType"
            @change="dataInput('assistenceType', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderAssistance.label.phoneFree')"
          label-for="phone-free-input"
        >
          <b-form-input
            id="phone-free-input"
            :value="formData.phoneFree"
            trim
            data-test="phone-free-input"
            :readonly="readonly"
            @update="dataInput('phoneFree', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderAssistance.label.assistenceEmail')"
          label-for="assistence-email-input"
        >
          <b-form-input
            id="assistence-email-input"
            :value="formData.assistenceEmail"
            trim
            data-test="assistence-email-input"
            :readonly="readonly"
            @update="dataInput('assistenceEmail', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderAssistance.label.assistenceObs')"
          label-for="assistence-obs-input"
        >
          <b-form-textarea
            id="assistence-obs-input"
            :value="formData.assistenceObs"
            data-test="assistence-obs-input"
            :readonly="readonly"
            @update="dataInput('assistenceObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderAssistance',
  mixins: [orderInputsMixin],
}
</script>

<style scoped>

</style>
