<template>
  <div>
    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderInformation.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.contract')"
          label-for="contract-number-input"
        >
          <b-form-input
            id="contract-number-input"
            :value="contractNumber"
            trim
            readonly
            data-test="contract-number-input"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.signedAt')"
          label-for="signed-at-input"
        >
          <date-picker
            id="signed-at-input"
            v-model="dateInputs['signedAt']"
            data-test="signed-at-input"
            :hide="readonly"
            :field-state="checkField('signedAt')"
            @change="setDate('signedAt', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.volume')"
          label-for="car-qty-input"
        >
          <b-form-input
            id="car-qty-input"
            number
            data-test="car-qty-input"
            type="number"
            :readonly="readonly"
            :state="checkField('volume')"
            :value="formData.volume"
            @update="dataInput('volume', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.period')"
          label-for="period-input"
        >
          <b-input-group :append="$t('orderModal.labels.months')">
            <b-form-input
              id="period-input"
              number
              data-test="period-input"
              max="99"
              maxlength="3"
              type="number"
              :readonly="readonly"
              :state="checkField('period')"
              :value="formData.period"
              @update="dataInput('period', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.status')"
          label-for="status-input"
        >
          <b-form-select
            id="status-input"
            :value="formData.status"
            data-test="status-input"
            :disabled="readonly"
            :options="inputOptions['status']"
            :state="checkField('status')"
            @change="dataInput('status', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInformation.label.type')"
          label-for="type-input"
        >
          <b-form-select
            id="type-input"
            data-test="type-input"
            :disabled="readonly"
            :options="inputOptions['type']"
            :state="checkField('type')"
            :value="formData.type"
            @change="dataInput('type', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '../orderInputsMixin';

export default {
  name: 'OrderInformation',
  mixins: [orderInputsMixin],
  computed: {
    contractNumber() {
      return (
        this.formData.contract?.contractNumber
        || this.contractId
      );
    },
  },
  mounted() {
    this.dateInputs['signedAt'] = this.formData.signedAt;
  },
}
</script>
