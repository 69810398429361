<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderTracking.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderTracking.label.trackerType')"
          label-for="tracker-type-input"
        >
          <b-form-select
            id="tracker-type-input"
            data-test="tracker-type-input"
            :disabled="readonly"
            :options="inputOptions['organizationOptions']"
            :state="checkField('trackerType')"
            :value="formData.trackerType"
            @change="dataInput('trackerType', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderTracking.label.safecarType')"
          label-for="safecar-type-input"
        >
          <b-form-select
            id="safecar-type-input"
            :value="formData.safecarType"
            data-test="safecar-type-input"
            :options="inputOptions['organizationNullableOptions']"
            :state="checkField('safecarType')"
            :disabled="readonly"
            @change="dataInput('safecarType', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group
          :label="$t('orderModal.sections.orderTracking.label.rentalObs')"
          label-for="rental-obs-input"
        >
          <b-form-textarea
            id="rental-obs-input"
            data-test="rental-obs-input"
            :value="formData.rentalObs"
            :readonly="readonly"
            @update="dataInput('rentalObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: "OrderTracking",
  mixins: [orderInputsMixin],
}
</script>
