var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderInformation.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.contract"
                    ),
                    "label-for": "contract-number-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "contract-number-input",
                      value: _vm.contractNumber,
                      trim: "",
                      readonly: "",
                      "data-test": "contract-number-input",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.signedAt"
                    ),
                    "label-for": "signed-at-input",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "signed-at-input",
                      "data-test": "signed-at-input",
                      hide: _vm.readonly,
                      "field-state": _vm.checkField("signedAt"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setDate("signedAt", $event)
                      },
                    },
                    model: {
                      value: _vm.dateInputs["signedAt"],
                      callback: function ($$v) {
                        _vm.$set(_vm.dateInputs, "signedAt", $$v)
                      },
                      expression: "dateInputs['signedAt']",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.volume"
                    ),
                    "label-for": "car-qty-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "car-qty-input",
                      number: "",
                      "data-test": "car-qty-input",
                      type: "number",
                      readonly: _vm.readonly,
                      state: _vm.checkField("volume"),
                      value: _vm.formData.volume,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("volume", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.period"
                    ),
                    "label-for": "period-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { append: _vm.$t("orderModal.labels.months") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "period-input",
                          number: "",
                          "data-test": "period-input",
                          max: "99",
                          maxlength: "3",
                          type: "number",
                          readonly: _vm.readonly,
                          state: _vm.checkField("period"),
                          value: _vm.formData.period,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.dataInput("period", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.status"
                    ),
                    "label-for": "status-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "status-input",
                      value: _vm.formData.status,
                      "data-test": "status-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["status"],
                      state: _vm.checkField("status"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("status", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderInformation.label.type"
                    ),
                    "label-for": "type-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "type-input",
                      "data-test": "type-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["type"],
                      state: _vm.checkField("type"),
                      value: _vm.formData.type,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("type", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }