var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formMode === "update"
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12" } }, [
                _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("orderModal.sections.orderUpdate.title")) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "orderModal.sections.orderUpdate.label.revisionObs"
                        ),
                        "label-for": "revision-obs-input",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "revision-obs-input",
                          "data-test": "revision-obs-input",
                          trim: "",
                          readonly: _vm.readonly,
                          value: _vm.formData.revisionObs,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.dataInput("revisionObs", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-dd-divider", { staticClass: "mb-4" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }