import {
  BillingKindEnum,
  LocalEnum,
  OrderTypeEnum,
  OrganizationEnum,
  OrganizationNullableEnum,
  STATUS,
} from '@enums/order';

export default {
  actualSaleValue: 0,
  amountReceived: 0,
  anticipation: '',
  anticipationDevolution: '',
  anticipationDevolutionFine: '',
  anticipationObs: '',
  assistenceEmail: '',
  assistenceObs: '',
  assistenceType: OrganizationEnum.KOVI,
  billingKind: BillingKindEnum.BILLING_ANTICIPATED,
  billingObs: '',
  billingOverdue: '',
  billingReadjust: '',
  bookingObs: '',
  carDealingObs: '',
  contract: '', //ID!
  debtBalance: 0,
  deliveryLocal: LocalEnum.KOVI,
  deliveryObs: '',
  devolutionLocal: LocalEnum.KOVI,
  devolutionObs: '',
  duedateStartBooking: '',
  estimatedSalesValue: 0,
  guaranteedValue: 0,
  hasResidual: false,
  incidentAlertTime: '',
  incidentContactEmail: '',
  incidentDateBoEmitted: '',
  kmContracted: 0, // Int
  kmLimitExceeded: 0, // Int
  kmMax: 0, // Int
  letterOfGuarantee: 0, // Float
  model: '',
  notRun: 0, // Float
  payDay: '',
  period: 0, // Int
  phoneFree: '',
  poolKm: false, // Boolean
  pricingAmount: 0, // Int
  pricingExtraKm: 0, // Int
  purchaseDate: '',
  purchasePrice: 0,
  rentalObs: '',
  retentionFine: 0, // Float
  safecarType: OrganizationNullableEnum.NONE,
  saleDate: '',
  securyDamageMaterial: 0, // Float
  securyDamageMoral: 0, // Float
  securyDamagePersonal: 0, // Float
  securyHillFranchise: 0, // Float
  securyHillResponsable: '',
  securyHillType: OrganizationEnum.KOVI,
  securyPtFipeMetrics: 0, // Float
  securyPtFipePayment: 0, // Float
  securyPtFipeTheft: 0, // Float
  securyRcfFranchise: 0, // Float
  securyRcfResponsable: '',
  securyRcfType: OrganizationEnum.KOVI,
  securySingleWarranty: 0, // Float
  signedAt: '', // Date
  status: STATUS.ACTIVE,
  trackerType: OrganizationEnum.KOVI,
  trafficAdmRate: 0, // Float
  trafficFine: OrganizationEnum.KOVI,
  trafficObs: '',
  trafficResponsable: '',
  type: OrderTypeEnum.NEW,
  volume: 0, // Int
  //created_at	String
  //update_date	String
  //update_kind	String
  //update_reason	String
  //updated_at	String
}
