<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderCarDealing.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.purchasePrice')"
          label-for="purchase-price-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="purchase-price-input"
              class="form-control"
              data-test="purchase-price-input"
              v-bind="money"
              :class="checkMoneyState('purchasePrice')"
              :readonly="readonly"
              :value="moneyInput(formData.purchasePrice)"
              @input="moneyOutput('purchasePrice', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.purchaseDate')"
          label-for="purchase-date-input"
        >
          <date-picker
            id="purchase-date-input"
            v-model="dateInputs['purchaseDate']"
            data-test="purchase-date-input"
            :hide="readonly"
            @change="setDate('purchaseDate', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.estimatedSalesValue')"
          label-for="estimated-sales-value-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="estimated-sales-value-input"
              class="form-control"
              data-test="estimated-sales-value-input"
              v-bind="money"
              :class="checkMoneyState('estimatedSalesValue')"
              :readonly="readonly"
              :value="moneyInput(formData.estimatedSalesValue)"
              @input="moneyOutput('estimatedSalesValue', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.actualSaleValue')"
          label-for="actual-sale-value-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="actual-sale-value-input"
              class="form-control"
              data-test="actual-sale-value-input"
              v-bind="money"
              :class="checkMoneyState('actualSaleValue')"
              :readonly="readonly"
              :value="moneyInput(formData.actualSaleValue)"
              @input="moneyOutput('actualSaleValue', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.saleDate')"
          label-for="sale-date-input"
        >
          <date-picker
            id="sale-date-input"
            v-model="dateInputs['saleDate']"
            data-test="sale-date-input"
            :hide="readonly"
            @change="setDate('saleDate', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-form-group
          :label="$t('orderModal.sections.orderCarDealing.label.carDealingObs')"
          label-for="car-dealing-obs-input"
        >
          <b-form-textarea
            id="car-dealing-obs-input"
            data-test="car-dealing-obs-input"
            :readonly="readonly"
            :value="formData.carDealingObs"
            @update="dataInput('carDealingObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderBooking',
  mixins: [orderInputsMixin],
  mounted() {
    this.dateInputs['purchaseDate'] = this.formData.purchaseDate;
    this.dateInputs['saleDate'] = this.formData.saleDate;
  },
}
</script>
