export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const OrderTypeEnum = {
  NEW: 'NEW',
  INCLUSION: 'INCLUSION',
  RENOVATION: 'RENOVATION',
}

export const OrganizationEnum = {
  KOVI: 'KOVI',
  LOCADORA: 'LOCADORA',
}

export const OrganizationNullableEnum = {
  KOVI: 'KOVI',
  LOCADORA: 'LOCADORA',
  NONE: 'NONE',
}

export const BillingKindEnum = {
  BILLING_ANTICIPATED: 'BILLING_ANTICIPATED',
  BILLING_FILL_MONTH: 'BILLING_FILL_MONTH',
  BILLING_PRO_RATA: 'BILLING_PRO_RATA',
}

export const LocalEnum = {
  KOVI: 'KOVI',
  OTHERS: 'OTHERS',
}
