<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderDelivery.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.duedateStartBooking')"
          label-for="duedate-start-booking-input"
        >
          <b-form-input
            id="duedate-start-booking-input"
            trim
            data-test="duedate-start-booking-input"
            :readonly="readonly"
            :value="formData.duedateStartBooking"
            @update="dataInput('duedateStartBooking', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.retentionFine')"
          label-for="retention-fine-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="retention-fine-input"
              trim
              data-test="retention-fine-input"
              number
              type="number"
              :readonly="readonly"
              :state="checkField('retentionFine')"
              :value="formData.retentionFine"
              @update="dataInput('retentionFine', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.deliveryLocal')"
          label-for="delivery-local-input"
        >
          <b-form-select
            id="delivery-local-input"
            data-test="delivery-local-input"
            :options="inputOptions['localEnumOptions']"
            :disabled="readonly"
            :state="checkField('deliveryLocal')"
            :value="formData.deliveryLocal"
            @change="dataInput('deliveryLocal', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.devolutionLocal')"
          label-for="devolution-local-input"
        >
          <b-form-select
            id="devolution-local-input"
            data-test="devolution-local-input"
            :options="inputOptions['localEnumOptions']"
            :disabled="readonly"
            :state="checkField('devolutionLocal')"
            :value="formData.devolutionLocal"
            @change="dataInput('devolutionLocal', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="6">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.deliveryObs')"
          label-for="delivery-obs-input"
        >
          <b-form-textarea
            id="delivery-obs-input"
            data-test="delivery-obs-input"
            :readonly="readonly"
            :value="formData.deliveryObs"
            @update="dataInput('deliveryObs', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.devolutionObs')"
          label-for="devolution-obs-input"
        >
          <b-form-textarea
            id="devolution-obs-input"
            data-test="devolution-obs-input"
            :readonly="readonly"
            :value="formData.devolutionObs"
            @update="dataInput('devolutionObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-form-group
          :label="$t('orderModal.sections.orderDelivery.label.anticipationDevolutionFine')"
          label-for="anticipation-devolution-fine-input"
        >
          <b-form-textarea
            id="anticipation-devolution-fine-input"
            data-test="anticipation-devolution-fine-input"
            :readonly="readonly"
            :value="formData.anticipationDevolutionFine"
            @update="dataInput('anticipationDevolutionFine', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderDelivery',
  mixins: [orderInputsMixin],
  props: {
    contract: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
