<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderInsurance.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyHillType')"
          label-for="secury-hill-type-input"
        >
          <b-form-select
            id="secury-hill-type-input"
            data-test="secury-hill-type-input"
            :disabled="readonly"
            :options="inputOptions['organizationOptions']"
            :state="checkField('securyHillType')"
            :value="formData.securyHillType"
            @change="dataInput('securyHillType', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyHillResponsable')"
          label-for="secury-hill-responsable-input"
        >
          <b-form-input
            id="secury-hill-responsable-input"
            trim
            data-test="secury-hill-responsable-input"
            :readonly="readonly"
            :state="checkField('securyHillResponsable')"
            :value="formData.securyHillResponsable"
            @update="dataInput('securyHillResponsable', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyHillFranchise')"
          label-for="secury-hill-franchise-input"
        >
          <b-form-input
            id="secury-hill-franchise-input"
            trim
            data-test="secury-hill-franchise-input"
            number
            type="number"
            :readonly="readonly"
            :state="checkField('securyHillFranchise')"
            :value="formData.securyHillFranchise"
            @update="dataInput('securyHillFranchise', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyPtFipeMetrics')"
          label-for="secury-pt-fipe-metrics-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="secury-pt-fipe-metrics-input"
              trim
              data-test="secury-pt-fipe-metrics-input"
              number
              type="number"
              :readonly="readonly"
              :state="checkField('securyPtFipeMetrics')"
              :value="formData.securyPtFipeMetrics"
              @update="dataInput('securyPtFipeMetrics', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyPtFipePayment')"
          label-for="secury-pt-fipe-payment-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="secury-pt-fipe-payment-input"
              trim
              data-test="secury-pt-fipe-payment-input"
              number
              type="number"
              :readonly="readonly"
              :state="checkField('securyPtFipePayment')"
              :value="formData.securyPtFipePayment"
              @update="dataInput('securyPtFipePayment', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyPtFipeTheft')"
          label-for="secury-pt-fipe-theft-input"
        >
          <b-input-group append="%">
            <b-form-input
              id="secury-pt-fipe-theft-input"
              trim
              data-test="secury-pt-fipe-theft-input"
              number
              type="number"
              :readonly="readonly"
              :value="formData.securyPtFipeTheft"
              @update="dataInput('securyPtFipeTheft', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyRcfType')"
          label-for="secury-rcf-type-input"
        >
          <b-form-select
            id="secury-rcf-type-input"
            data-test="secury-rcf-type-input"
            :disabled="readonly"
            :options="inputOptions['organizationOptions']"
            :state="checkField('securyRcfType')"
            :value="formData.securyRcfType"
            @change="dataInput('securyRcfType', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyRcfResponsable')"
          label-for="secury-rcf-responsable-input"
        >
          <b-form-input
            id="secury-rcf-responsable-input"
            trim
            data-test="secury-rcf-responsable-input"
            :readonly="readonly"
            :state="checkField('securyRcfResponsable')"
            :value="formData.securyRcfResponsable"
            @update="dataInput('securyRcfResponsable', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyRcfFranchise')"
          label-for="secury-rcf-franchise-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="secury-rcf-franchise-input"
              class="form-control"
              data-test="secury-rcf-franchise-input"
              v-bind="money"
              :class="checkMoneyState('securyRcfFranchise')"
              :readonly="readonly"
              :value="moneyInput(formData.securyRcfFranchise)"
              @input="moneyOutput('securyRcfFranchise', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyDamageMaterial')"
          label-for="secury-damage-material-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="secury-damage-material-input"
              class="form-control"
              data-test="secury-damage-material-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.securyDamageMaterial)"
              @input="moneyOutput('securyDamageMaterial', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyDamagePersonal')"
          label-for="secury-damage-personal-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="secury-damage-personal-input"
              class="form-control"
              data-test="secury-damage-personal-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.securyDamagePersonal)"
              @input="moneyOutput('securyDamagePersonal', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securySingleWarranty')"
          label-for="secury-single-warranty-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="secury-single-warranty-input"
              class="form-control"
              data-test="secury-single-warranty-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.securySingleWarranty)"
              @input="moneyOutput('securySingleWarranty', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderInsurance.label.securyDamageMoral')"
          label-for="secury-damage-moral-input"
        >
          <b-input-group :prepend="$t('orderModal.labels.money')">
            <money
              id="secury-damage-moral-input"
              class="form-control"
              data-test="secury-damage-moral-input"
              v-bind="money"
              :readonly="readonly"
              :value="moneyInput(formData.securyDamageMoral)"
              @input="moneyOutput('securyDamageMoral', $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderInsurance',
  mixins: [orderInputsMixin],
}
</script>

<style scoped>

</style>
