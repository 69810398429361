var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderBilling.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBilling.label.billingKind"
                    ),
                    "label-for": "billing-kind-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "billing-kind-input",
                      "data-test": "billing-kind-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["billingKind"],
                      state: _vm.checkField("billingKind"),
                      value: _vm.formData.billingKind,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("billingKind", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBilling.label.billingOverdue"
                    ),
                    "label-for": "billing-overdue-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "billing-overdue-input",
                      trim: "",
                      "data-test": "billing-overdue-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("billingOverdue"),
                      value: _vm.formData.billingOverdue,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("billingOverdue", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBilling.label.billingReadjust"
                    ),
                    "label-for": "billing-readjust-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "billing-readjust-input",
                      trim: "",
                      "data-test": "billing-readjust-input",
                      readonly: _vm.readonly,
                      state: _vm.checkField("billingReadjust"),
                      value: _vm.formData.billingReadjust,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("billingReadjust", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderBilling.label.billingObs"
                    ),
                    "label-for": "billing-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "billing-obs-input",
                      "data-test": "billing-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.billingObs,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("billingObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }