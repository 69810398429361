var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderCarDealing.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.purchasePrice"
                    ),
                    "label-for": "purchase-price-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("purchasePrice"),
                            attrs: {
                              id: "purchase-price-input",
                              "data-test": "purchase-price-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(_vm.formData.purchasePrice),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput("purchasePrice", $event)
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.purchaseDate"
                    ),
                    "label-for": "purchase-date-input",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "purchase-date-input",
                      "data-test": "purchase-date-input",
                      hide: _vm.readonly,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setDate("purchaseDate", $event)
                      },
                    },
                    model: {
                      value: _vm.dateInputs["purchaseDate"],
                      callback: function ($$v) {
                        _vm.$set(_vm.dateInputs, "purchaseDate", $$v)
                      },
                      expression: "dateInputs['purchaseDate']",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.estimatedSalesValue"
                    ),
                    "label-for": "estimated-sales-value-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("estimatedSalesValue"),
                            attrs: {
                              id: "estimated-sales-value-input",
                              "data-test": "estimated-sales-value-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.estimatedSalesValue
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput(
                                  "estimatedSalesValue",
                                  $event
                                )
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.actualSaleValue"
                    ),
                    "label-for": "actual-sale-value-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: _vm.$t("orderModal.labels.money") } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            class: _vm.checkMoneyState("actualSaleValue"),
                            attrs: {
                              id: "actual-sale-value-input",
                              "data-test": "actual-sale-value-input",
                              readonly: _vm.readonly,
                              value: _vm.moneyInput(
                                _vm.formData.actualSaleValue
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.moneyOutput(
                                  "actualSaleValue",
                                  $event
                                )
                              },
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.saleDate"
                    ),
                    "label-for": "sale-date-input",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "sale-date-input",
                      "data-test": "sale-date-input",
                      hide: _vm.readonly,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setDate("saleDate", $event)
                      },
                    },
                    model: {
                      value: _vm.dateInputs["saleDate"],
                      callback: function ($$v) {
                        _vm.$set(_vm.dateInputs, "saleDate", $$v)
                      },
                      expression: "dateInputs['saleDate']",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderCarDealing.label.carDealingObs"
                    ),
                    "label-for": "car-dealing-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "car-dealing-obs-input",
                      "data-test": "car-dealing-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.carDealingObs,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("carDealingObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }