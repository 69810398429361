<template>
  <div>
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderBilling.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBilling.label.billingKind')"
          label-for="billing-kind-input"
        >
          <b-form-select
            id="billing-kind-input"
            data-test="billing-kind-input"
            :disabled="readonly"
            :options="inputOptions['billingKind']"
            :state="checkField('billingKind')"
            :value="formData.billingKind"
            @change="dataInput('billingKind', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBilling.label.billingOverdue')"
          label-for="billing-overdue-input"
        >
          <b-form-input
            id="billing-overdue-input"
            trim
            data-test="billing-overdue-input"
            :readonly="readonly"
            :state="checkField('billingOverdue')"
            :value="formData.billingOverdue"
            @update="dataInput('billingOverdue', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBilling.label.billingReadjust')"
          label-for="billing-readjust-input"
        >
          <b-form-input
            id="billing-readjust-input"
            trim
            data-test="billing-readjust-input"
            :readonly="readonly"
            :state="checkField('billingReadjust')"
            :value="formData.billingReadjust"
            @update="dataInput('billingReadjust', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group
          :label="$t('orderModal.sections.orderBilling.label.billingObs')"
          label-for="billing-obs-input"
        >
          <b-form-textarea
            id="billing-obs-input"
            data-test="billing-obs-input"
            :readonly="readonly"
            :value="formData.billingObs"
            @change="dataInput('billingObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderBilling',
  mixins: [orderInputsMixin],
}
</script>

<style scoped>

</style>
