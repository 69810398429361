<template>
  <div
    v-if="formMode === 'update'"
  >
    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderUpdate.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-form-group
          :label="$t('orderModal.sections.orderUpdate.label.revisionObs')"
          label-for="revision-obs-input"
        >
          <b-form-textarea
            id="revision-obs-input"
            data-test="revision-obs-input"
            trim
            :readonly="readonly"
            :value="formData.revisionObs"
            @update="dataInput('revisionObs', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-dd-divider class="mb-4"/>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderUpdate',
  mixins: [ orderInputsMixin ],
  computed: {
    originOrderId() {
      return (
        this.formData.contract?.contractNumber
        || this.contractId
      );
    },
  },
}
</script>
