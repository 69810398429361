var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-dd-divider", { staticClass: "mb-4" }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderModal.sections.orderDelivery.title")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.duedateStartBooking"
                    ),
                    "label-for": "duedate-start-booking-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "duedate-start-booking-input",
                      trim: "",
                      "data-test": "duedate-start-booking-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.duedateStartBooking,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("duedateStartBooking", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.retentionFine"
                    ),
                    "label-for": "retention-fine-input",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { append: "%" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "retention-fine-input",
                          trim: "",
                          "data-test": "retention-fine-input",
                          number: "",
                          type: "number",
                          readonly: _vm.readonly,
                          state: _vm.checkField("retentionFine"),
                          value: _vm.formData.retentionFine,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.dataInput("retentionFine", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.deliveryLocal"
                    ),
                    "label-for": "delivery-local-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "delivery-local-input",
                      "data-test": "delivery-local-input",
                      options: _vm.inputOptions["localEnumOptions"],
                      disabled: _vm.readonly,
                      state: _vm.checkField("deliveryLocal"),
                      value: _vm.formData.deliveryLocal,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("deliveryLocal", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.devolutionLocal"
                    ),
                    "label-for": "devolution-local-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "devolution-local-input",
                      "data-test": "devolution-local-input",
                      options: _vm.inputOptions["localEnumOptions"],
                      disabled: _vm.readonly,
                      state: _vm.checkField("devolutionLocal"),
                      value: _vm.formData.devolutionLocal,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("devolutionLocal", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.deliveryObs"
                    ),
                    "label-for": "delivery-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "delivery-obs-input",
                      "data-test": "delivery-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.deliveryObs,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("deliveryObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.devolutionObs"
                    ),
                    "label-for": "devolution-obs-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "devolution-obs-input",
                      "data-test": "devolution-obs-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.devolutionObs,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("devolutionObs", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "orderModal.sections.orderDelivery.label.anticipationDevolutionFine"
                    ),
                    "label-for": "anticipation-devolution-fine-input",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "anticipation-devolution-fine-input",
                      "data-test": "anticipation-devolution-fine-input",
                      readonly: _vm.readonly,
                      value: _vm.formData.anticipationDevolutionFine,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput(
                          "anticipationDevolutionFine",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }